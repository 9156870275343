export default class Helpers {
    static findJerseyImageModule(team, brand) {
        const formattedTeamName = this.formatTextForAssetNames(team);
        const possibleFormats = ['png', 'webp'];

        for (const format of possibleFormats) {
            try {
                return require(`../assets/jerseys/${formattedTeamName}-kit.${format}`);
            } catch (e) {
            }
        }

        return require(`../brand/${brand}/assets/jerseys/default-player-jersey.webp`);
    }

    static findLeagueImageModule(league) {
        const name = typeof league === "object" ? league.leagueName : league;
        const reformattedLeagueName = this.formatTextForAssetNames(name);
        const possibleFormats = ['webp', 'png'];

        for (const format of possibleFormats) {
            try {
                return require(`../assets/leagueLogos/${reformattedLeagueName}-logo-colour.${format}`);
            } catch (e) {
            }
        }

        return require('../assets/default-badge.webp');
    }

    static findTeamImageModule(teamName) {
        const reformattedTeamName = this.formatTextForAssetNames(teamName);
        const possibleFormats = ['png', 'webp'];

        for (const format of possibleFormats) {
            try {
                return require(`../assets/teamBadges/${reformattedTeamName}-logo-icon.${format}`);
            } catch (e) {
            }
        }
        return require('../assets/default-badge.webp');
    }

    static formatTextForAssetNames(text) {
        const lowerCase = text.toLowerCase();
        return lowerCase.replaceAll(' ', '-');
    }
}
